/* eslint-disable import/no-named-as-default */
/* global window, document */
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from 'react-redux-multilingual';
import tagManager from 'react-gtm-module';
import ErrorBoundary from 'Views/Shared/ErrorBoundary/ErrorBoundary';
import translations from './SiteConfigs/translations-config';
import { requestUserInfo } from './Actions/UserActions';
import Routes from './Routes/Routes';
import configureStore from './SiteConfigs/configureStore';
import format from './Utils/format';
import IframeCommunication from './Utils/iframeCommunication';
import './assets/styles/bootstrap/bootstrap.scss';
import './assets/styles/index.scss';

const rootElement = document.getElementById('root');
const { MD } = window;
window.format = format;
window.iframeCommunication = new IframeCommunication();

tagManager.initialize({
	gtmId: MD.gtm_id,
	auth: MD.gtm_auth,
	preview: MD.gtm_preview
});

const storeConfiguration = configureStore();

// initing an instance of format that will have global props set
// removing the need to import it in every file.
// https://format.dev.services.mdgapp.net/

// make the request for the user info.
if (MD && MD.apiBaseCibcUrl) {
	storeConfiguration.store.dispatch(requestUserInfo());
	window.onpopstate = () => {
		// ensures that we pass the location to CIBC on browser back
		window.iframeCommunication.sendNavigation(window.location.pathname);
		storeConfiguration.store.dispatch(requestUserInfo());
	};
}

ReactDOM.render(
	<ErrorBoundary>
		<Provider store={storeConfiguration.store}>
			<PersistGate loading={null} persistor={storeConfiguration.persistor}>
				<IntlProvider translations={translations}>
					<Routes />
				</IntlProvider>
			</PersistGate>
		</Provider>
	</ErrorBoundary>, rootElement
);
