/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import TagManager from 'react-gtm-module';
import { BUILD_DATE, BUILD_VERSION } from './buildConstants';

export function sendEventTrackingDetails(isSiteInteraction = false, isDownload = false, interactionName = '', fileName = '', fileType = '') {
	const trackingObj = {};
	if (isSiteInteraction) {
		trackingObj.events = {
			siteInteraction: true
		};

		trackingObj.interaction = {
			name: `cibc:obr:quotes-research:${interactionName}`
		};
	}
	if (isDownload) {
		trackingObj.events = {
			download: true
		};
		trackingObj.download = {
			fileName,
			fileType
		};
	}

	window.digitalData = {
		...window.digitalData,
		...trackingObj
	};

	if (typeof _satellite !== 'undefined') {
		console.log('track obj', _satellite);
		_satellite.track('track');
	}
}

export function sendGAEvent(eventName = '', category = '', action = '', label = '', value = '') {
	TagManager.dataLayer({
		dataLayer: {
			event: eventName,
			eventAction: action,
			eventCategory: category,
			eventLabel: label,
			eventValue: value
		}

	});
}

/* Page level tracking */
export function sendOmnitureDetails(pageName = '', pageUrl = '', pageReferrer = '', pageHierarchy = [], language = 'en', userType = '', userId = '', hasError = false) {
	const { MD } = window;
	const environment = ((MD && MD.environment && MD.environment.length) ? MD.environment : 'production');

	let omnitureObj = {
		events: {
			pageView: true
		},
		site: {
			brand: 'cibc',
			name: 'obr',
			type: 'desktop',
			environment,
			appVersion: BUILD_VERSION,
			lastBuildDate: BUILD_DATE
		},
		page: {
			name: pageName,
			url: pageUrl,
			referrer: pageReferrer,
			hierarchy: pageHierarchy,
			language,
			accessibility: true
		},
		user: {
			authState: !hasError ? 'authenticated' : 'unauthorized',
			type: userType,
			ID: userId && userId.replace(/ /g, '+')
		}
	};

	if (hasError) {
		omnitureObj = {
			...omnitureObj,
			error: {
				code: 'obr-markit-session-timeout'
			}
		};
	}

	window.digitalData = {
		...omnitureObj
	};

	if (typeof _satellite !== 'undefined') {
		console.log('track obj', _satellite);
		_satellite.track('track');
	}
}
